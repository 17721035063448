import axios from "axios";
import { getToken } from "../../utils/general";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

export const getMasterData = () => axios.get(`${baseUrl}/master`);

export const getMasterCountryData = () => axios.get(`${baseUrl}/master/countries`);

export const getFleetDetails = (fleetId: string) =>
  axios.get(`${baseUrl}/fleets/${fleetId}`, {
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });
