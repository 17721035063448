import { useFormik } from "formik";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import LandingPageLayout from "../../layout/LandingPageLayout";
import Button from "../CommonSection/Buttons/Button";
import Input from "../CommonSection/Input/Input";
import classes from "./Login.module.scss";
import { useLogin } from "../../hooks/login/login";
import TableViewIcon from "../../Icons/TableViewIcon";
import { getFleetDetails, getMasterData } from "../../lib/api/generalApi";
import { login as loginAction, setConfigured } from "../../Store/Slices/AuthSlice";
import { getMasterData as getMasterDataAction } from "../../Store/Slices/MasterSlice";
import { setCookie } from "../../utils/general";
import { errorToast } from "../../utils/toast";
// import { RootState } from "../../Store/Store";
import { ROLE_SHORT_CODE } from "../../utils/status";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Please enter your Email").email("Please enter valid email"),
  password: Yup.string().required("Please enter your Password"),
});

const Register: React.FC = (): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const loginMutation = useMutation(useLogin);
  const dispatch = useDispatch();
  const [passwodrdInputType, setPasswodrdInputType] = useState("password");
  const [userData, setUserData] = useState();

  const masterApi = useQuery({
    queryKey: ["getMasterData"],
    queryFn: () => getMasterData(),
    onSuccess: (data) => {
      dispatch(getMasterDataAction(data.data.data));
    },
    enabled: false,
  });

  useEffect(() => {
    masterApi.refetch();
  }, []);

  // const fleetId = useSelector((state: RootState) => state.auth?.user?.profile?.fleet_id);
  const statusCheck = useQuery({
    queryKey: ["getFleetData", userData],
    // @ts-ignore
    queryFn: () => getFleetDetails(userData?.profile?.fleet_id),
    onSuccess: (data) => {
      dispatch(loginAction(userData));
      dispatch(setConfigured(data.data.data?.last_setup_step));
      if (data.data.data?.last_setup_step >= 3) {
        window.location.replace("/dashboard");
      } else {
        window.location.replace("/configuration");
      }
    },
    onError: (error: any) => {
      setLoading(false);
      errorToast(error?.response?.data?.message || "Something went wrong!");
    },
    enabled: false,
  });

  useEffect(() => {
    if (userData) {
      statusCheck.refetch();
    }
  }, [userData]);

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (fvalues): Promise<void> => {
      const buttonEle = document.getElementById("login_btn") as HTMLButtonElement;
      // buttonEle.disabled = true;
      setLoading(true);
      const reqData = {
        email: fvalues.email,
        password: fvalues.password,
      };
      try {
        loginMutation.mutate(reqData, {
          onSuccess: (data) => {
            if (data.data.data.profile.role_sc !== ROLE_SHORT_CODE.SUPER_ADMIN) {
              const { token } = data.data.data.authentication;
              const { refreshToken } = data.data.data.authentication;
              setCookie(token, refreshToken);
              setUserData(data.data.data);
              if (!data.data.data.profile.fleet_id) {
                dispatch(loginAction(data.data.data));
                window.location.replace("/dashboard");
              }
            } else {
              errorToast("Invalid User!");
            }
            setLoading(false);
          },
          onError: (error: any) => {
            setLoading(false);
            errorToast(error?.response?.data?.message || "Something went wrong! Please try again");
            buttonEle.disabled = false;
          },
        });
      } catch (err) {
        setLoading(false);
        errorToast("Somethimg went wrong!");
        buttonEle.disabled = false;
      }
    },
  });

  return (
    <LandingPageLayout>
      <div className={`${classes.login_section}`}>
        <div className={`${classes.login_container}`}>
          <div className={`${classes.login_page_title} font-display`}>Welcome to IntuCharge</div>
          <div className={`${classes.login_box}`}>
            <div className="w-full grid">
              <Image
                src="/images/loginimg.png"
                alt="Image"
                layout="responsive"
                width={994}
                height={619}
                objectFit="contain"
              />
            </div>
            <div className={`${classes.login_separates}`}>
              <div className={`${classes.login_card}`}>
                <h1 className={`${classes.login_title} font-display`}>Login To Your Account</h1>

                <div className={`${classes.login_form_separates}`}>
                  <form onSubmit={handleSubmit} className={`${classes.login_details_separates}`}>
                    <div className="flex flex-col gap-6">
                      <Input
                        id="email"
                        label="Email Address"
                        type="text"
                        value={values.email}
                        error={errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.email}
                      />
                      <Input
                        id="password"
                        label="Password"
                        type={passwodrdInputType}
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.password}
                        inputProps={{
                          endAdornment: (
                            <span
                              className="cursor-pointer"
                              role="button"
                              aria-hidden="true"
                              onClick={() => {
                                const inputType =
                                  passwodrdInputType === "password" ? "text" : "password";
                                setPasswodrdInputType(inputType);
                              }}
                            >
                              <TableViewIcon />
                            </span>
                          ),
                        }}
                      />
                      <div className={`${classes.login_pw}`} title="Forgot Password">
                        <Link href="/forgot-password">Forgot Password?</Link>
                      </div>
                    </div>

                    <div className="mt-0 flex justify-center">
                      <Button
                        id="login_btn"
                        className="w-[268px] lg:h-[47px]"
                        variant="primary"
                        type="submit"
                        title="Login"
                      >
                        <ThreeDots
                          height="50"
                          width="50"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          visible={isLoading}
                        />
                        {!isLoading && "Login"}
                      </Button>
                    </div>
                  </form>
                  <div className={`${classes.create_text}`}>
                    Don&apos;t have an account?{" "}
                    <span className="text-primary-main" title="Create Account">
                      <Link href="/signup">Create Account.</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default Register;
