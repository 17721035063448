import axios from "axios";

const baseURL = process.env.NEXT_PUBLIC_BASE_URL;

export interface ILogin {
  email: string;
  password: string;
}

export const useLogin = (data: ILogin) => axios.post(`${baseURL}/account/login`, data);
